import { Components } from '../../components';

type DialogOptions = Omit<Partial<Components.SyDialog>, 'open' | 'close'>;
type HeaderOptions = Omit<Partial<Components.SyDialogHeader>, 'open' | 'close'>;
type ContentOptions = Omit<Partial<Components.SyDialogContent>, 'open' | 'close'>;
type FooterOptions = Omit<Partial<Components.SyDialogFooter>, 'open' | 'close'>;

// Rule lines-around-comment doesn't work with type and interface
// https://github.com/typescript-eslint/typescript-eslint/issues/1150
/* eslint-disable lines-around-comment */

export type CreateDialogOptions<ComponentPropsType = any> = DialogOptions & {
  /**
   * Set footer visibillity.
   */
  hideFooter?: boolean;

  /**
   * Set header visibillity.
   */
  hideHeader?: boolean;

  /**
   * Dialog header config. Use camelCase for property keys.
   */
  header: HeaderOptions & {
    customComponent?: HTMLElement;
    slotComponent?: string | HTMLElement;
    slotComponentPropsValues?: ComponentPropsType;
    styles?: Partial<CSSStyleDeclaration> & any;
  };

  /**
   * Dialog content config. Use camelCase for property keys.
   */
  content: ContentOptions & {
    customComponent?: HTMLElement;
    slotComponent?: string | HTMLElement;
    slotComponentPropsValues?: ComponentPropsType;
    styles?: Partial<CSSStyleDeclaration> & any;
  };

  /**
   * Dialog footer config. Use camelCase for property keys.
   */
  footer: FooterOptions & {
    customComponent?: HTMLElement;
    slotComponent?: string | HTMLElement;
    slotComponentPropsValues?: ComponentPropsType;
    styles?: Partial<CSSStyleDeclaration> & any;
  };

  /**
   * Dialog styles.
   */
  stylesDialog?: Partial<CSSStyleDeclaration> & any;

  /**
   * Element that sy-dialog will be appended. Whether not specified, sy-dialog is appended in `document.body`.
   *
   * Important: if you do not inform dialog position, the position value will be filled based on `elementToAppend`:
   * - When you define `elementToAppend`, sy-dialog has position=absolute.
   * - When you don't define `elementToAppend`, sy-dialog has position=fixed.
   */
  elementToAppend?: HTMLElement;
};

export interface DialogControllerContent {
  /**
   * Reference to the sy-dialog element, setted by the controller.
   */
  dialogRef?: HTMLSyDialogElement;
}

class DialogController {
  /**
   * Creates sy-dialog.
   * @param options Contains sy-dialog properties and configurations options to create dialog.
   */
  create<PropsType>(options: CreateDialogOptions<PropsType>) {
    const { hideHeader, hideFooter, header, content, footer, stylesDialog, elementToAppend, ...dialogProps } = options;

    const dialogEl = this.getDialogEl(stylesDialog, dialogProps);

    let dialogFooterEl;

    if (hideFooter !== true) {
      const { customComponent, slotComponent, slotComponentPropsValues, styles, ...footerProps } = footer;

      if (customComponent) {
        dialogFooterEl =
          typeof customComponent === 'string' ? document.createElement(customComponent) : customComponent;
      } else {
        dialogFooterEl = this.getFooterEl(styles, footerProps);

        const dialogSlotFooterEl = this.getDialogContentEl<PropsType>(
          dialogEl,
          slotComponent,
          slotComponentPropsValues,
        );
        if (dialogSlotFooterEl) {
          dialogFooterEl.appendChild(dialogSlotFooterEl);
        }
      }
    }

    let dialogHeaderEl;

    if (hideHeader !== true) {
      const { customComponent, slotComponent, slotComponentPropsValues, styles, ...headerProps } = header;

      if (customComponent) {
        dialogHeaderEl =
          typeof customComponent === 'string' ? document.createElement(customComponent) : customComponent;
      } else {
        dialogHeaderEl = this.getHeaderEl(styles, headerProps);

        const dialogSlotHeaderEl = this.getDialogContentEl<PropsType>(
          dialogEl,
          slotComponent,
          slotComponentPropsValues,
        );
        if (dialogSlotHeaderEl) {
          dialogHeaderEl.appendChild(dialogSlotHeaderEl);
        }
      }
    }

    let dialogContentEl;

    const { customComponent, slotComponent, slotComponentPropsValues, styles, ...contentProps } = content;
    if (customComponent) {
      dialogContentEl = typeof customComponent === 'string' ? document.createElement(customComponent) : customComponent;
    } else {
      dialogContentEl = this.getContentEl(styles, contentProps);
      const dialogSlotContentEl = this.getDialogContentEl<PropsType>(dialogEl, slotComponent, slotComponentPropsValues);
      if (dialogSlotContentEl) {
        dialogContentEl.appendChild(dialogSlotContentEl);
      }
    }

    if (dialogHeaderEl) {
      dialogEl.appendChild(dialogHeaderEl);
    }

    if (dialogContentEl) {
      dialogEl.appendChild(dialogContentEl);
    }

    if (dialogFooterEl) {
      dialogEl.appendChild(dialogFooterEl);
    }

    this.appendDialog(dialogEl, elementToAppend);

    return {
      element: dialogEl,
      open: () => dialogEl.open(),
      close: (action?: string) => dialogEl.close(action),
      onOpen: () => this.registerListener<string>('syDialogDidOpen', dialogEl),
      onClose: () => this.registerListener<any>('syDialogDidClose', dialogEl),
      onSubmit: () => this.registerListener<void>('syDialogDidSubmit', dialogEl),
      onCancel: () => this.registerListener<void>('syDialogDidCancel', dialogEl),
      willOpen: () => this.registerListener<void>('syDialogWillOpen', dialogEl),
      willClose: () => this.registerListener<void>('syDialogWillClose', dialogEl),
    };
  }

  private registerListener<TEventDetail>(eventName: string, dialogEl: HTMLSyDialogElement) {
    return new Promise<TEventDetail>((resolve) => {
      const listener = (e: CustomEvent) => this.tryResolve(dialogEl, e, resolve, listener);
      dialogEl.addEventListener(eventName, listener);
    });
  }

  private tryResolve(
    dialogCreatedEl: HTMLSyDialogElement,
    event: CustomEvent,
    resolveFn: (value: any) => void,
    listener: EventListener,
  ) {
    const target = event.composedPath()[0];
    const isFromCreatedDialog = target === dialogCreatedEl;
    if (isFromCreatedDialog) {
      resolveFn(event.detail);
      dialogCreatedEl.removeEventListener(event.type, listener);
    }
  }

  private appendDialog(dialogElement: HTMLSyDialogElement, elementToAppend: HTMLElement | undefined) {
    if (elementToAppend) {
      elementToAppend.appendChild(dialogElement);
    } else {
      document.body.appendChild(dialogElement);
    }
  }

  private getDialogEl(styles = {}, dialogProps = {}) {
    const dialogEl = document.createElement('sy-dialog');

    Object.keys(styles).forEach((styleKey) => {
      dialogEl.style.setProperty(styleKey, styles[styleKey]);
    });

    Object.keys(dialogProps).forEach((dialogPropKey) => {
      if (dialogPropKey in dialogEl) {
        dialogEl[dialogPropKey] = dialogProps[dialogPropKey];
      }
    });

    return dialogEl;
  }

  private getDialogContentEl<PropsType>(
    dialogEl: HTMLSyDialogElement,
    component?: string | HTMLElement,
    componentPropsValues?: PropsType,
  ) {
    if (component == null) return null;

    const dialogContentEl: HTMLElement & DialogControllerContent =
      typeof component === 'string' ? document.createElement(component) : component;
    dialogContentEl.dialogRef = dialogEl;

    if (componentPropsValues) {
      Object.keys(componentPropsValues).forEach((propKey) => {
        if (!(propKey in dialogContentEl)) {
          const componentName = typeof component === 'string' ? component : 'Dialog content';
          console.warn(`Component ${componentName} doesn't have "${propKey}" property.`);
          return;
        }
        dialogContentEl[propKey] = componentPropsValues[propKey];
      });
    }

    return dialogContentEl;
  }

  private getFooterEl(styles = {}, footerProps = {}) {
    const dialogEl = document.createElement('sy-dialog-footer');

    Object.keys(styles).forEach((styleKey) => {
      dialogEl.style.setProperty(styleKey, styles[styleKey]);
    });

    Object.keys(footerProps).forEach((footerPropKey) => {
      if (footerPropKey in dialogEl) {
        dialogEl[footerPropKey] = footerProps[footerPropKey];
      }
    });

    return dialogEl;
  }

  private getHeaderEl(styles = {}, footerProps = {}) {
    const dialogEl = document.createElement('sy-dialog-header');

    Object.keys(styles).forEach((styleKey) => {
      dialogEl.style.setProperty(styleKey, styles[styleKey]);
    });

    Object.keys(footerProps).forEach((footerPropKey) => {
      if (footerPropKey in dialogEl) {
        dialogEl[footerPropKey] = footerProps[footerPropKey];
      }
    });

    return dialogEl;
  }

  private getContentEl(styles = {}, footerProps = {}) {
    const dialogEl = document.createElement('sy-dialog-content');

    Object.keys(styles).forEach((styleKey) => {
      dialogEl.style.setProperty(styleKey, styles[styleKey]);
    });

    Object.keys(footerProps).forEach((footerPropKey) => {
      if (footerPropKey in dialogEl) {
        dialogEl[footerPropKey] = footerProps[footerPropKey];
      }
    });

    return dialogEl;
  }
}

export const dialogController = new DialogController();
