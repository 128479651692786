import Inputmask from 'inputmask';

export type InputMaskInstanceType = Inputmask.Instance;
export type InputMaskOptionsType = Inputmask.Options;

export function maskString(value: string, options: InputMaskOptionsType, prefix?: string): string {
  const maskedValue = Inputmask?.format(value, options);
  if (prefix && prefix.length > 0) {
    return prefix + maskedValue;
  }
  return maskedValue;
}
