/**
 * Throttle is used to execute the decorated method only once in a given time interval.
 * @param delay Time interval in milliseconds
 */
export function Throttle(delay: number) {
  return function (_target: any, key: string | symbol, descriptor: PropertyDescriptor): void {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any) {
      this.__shouldWait = this.__shouldWait || {};
      this.__waitingArgs = this.__waitingArgs || {};
      const setTimeoutFn = () => {
        if (this.__waitingArgs[key]) {
          originalMethod.call(this, ...this.__waitingArgs[key]);
          this.__waitingArgs[key] = null;
          setTimeout(setTimeoutFn, delay);
        } else {
          this.__shouldWait[key] = false;
        }
      };

      if (this.__shouldWait[key]) {
        this.__waitingArgs[key] = args;
        return;
      }

      this.__shouldWait[key] = true;
      originalMethod.call(this, ...args);

      setTimeout(setTimeoutFn, delay);
    };
  };
}
