import path from 'path';

type GenerateE2EPageArgs = {
  bodyContent: string | { dirname: string; htmlFileName?: string };
  testName?: string;
  noWaitForChanges?: boolean;
  noIcons?: boolean;
  headContent?: string;
};

interface Coordinate {
  x: number;
  y: number;
}

export class TestHelper {
  /**
   * @param page Expected Puppeteer E2EPage object;
   * @param fs Expected Node fs instance (import fs from 'fs';);
   */
  constructor(protected page: any, protected fs: any) {}
  PageCache = {};

  /**
   * Generates E2E Page with html template and test operations necessary for the correct execution of tests.
   * @param dirname Current dirname (use `__dirname` to indicate current directory)
   */
  async generateE2EPage(args: GenerateE2EPageArgs): Promise<any> {
    const html = this.getHTML(args);

    await this.page.setContent(html);
    if (args.noWaitForChanges) {
      return this.page;
    }
    await this.page.waitForChanges();
    return this.page;
  }

  async dragAndDrop(page: any, start: Coordinate, target: Coordinate, delay?: number): Promise<void> {
    await page.mouse.move(start.x, start.y);
    await page.mouse.down();
    await page.mouse.move(target.x, target.y);
    if (delay !== 0) {
      await page.waitForTimeout(delay);
    }
    await page.mouse.up();
  }

  private getHTML(args: GenerateE2EPageArgs) {
    const bodyContent = this.getBodyContent(args.bodyContent);
    const sydleUiIconsCdn = `<script type="module" src="https://package-sydle-ui.sydle.com/sydle-ui-icons@1.0.0/sydle-ui-icons.esm.js" async></script>`;

    return `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8" />
          <title>${args?.testName ?? 'Document'}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          ${(!args.noIcons && sydleUiIconsCdn) || ''}
          ${args?.headContent ?? ''}
        </head>
        <body>
          ${bodyContent}
        </body>
      </html>
    `;
  }

  private getBodyContent(bodyContent: GenerateE2EPageArgs['bodyContent']): string {
    if (typeof bodyContent === 'string') {
      return bodyContent;
    }

    const htmlFileName = bodyContent.htmlFileName ?? 'index.html';
    const cacheKey = path.join(bodyContent.dirname, htmlFileName);
    if (this.PageCache[cacheKey]) {
      return this.PageCache[cacheKey];
    }

    return this.fs.readFileSync(path.resolve(bodyContent.dirname, htmlFileName), 'utf-8');
  }
}
