function textArg(name: string, description?: string): { [k: string]: any } {
  return stringArg('text', name, description);
}

function colorArg(name: string, description?: string): { [k: string]: any } {
  return stringArg('color', name, description);
}

function dateArg(name: string, description?: string): { [k: string]: any } {
  return stringArg('date', name, description);
}

function stringArg(controlType: 'text' | 'color' | 'date', name: string, description?: string): { [k: string]: any } {
  const arg = {};
  arg[name] = {
    name,
    type: 'text',
    description,
    control: {
      type: controlType,
    },
  };
  return arg;
}

function numberArg(
  name: string,
  description?: string,
  options?: { min: number; max: number; step: number },
  useSlider?: boolean,
): { [k: string]: any } {
  const arg = {};
  arg[name] = {
    name,
    type: 'number',
    description,

    control: {
      type: useSlider ? 'range' : 'number',
      ...options,
    },
  };
  return arg;
}

function rangeArg(
  name: string,
  description?: string,
  options?: { min: number; max: number; step: number },
): { [k: string]: any } {
  return numberArg(name, description, options, true);
}

function radioArg(
  name: string,
  options: { [name: string]: string },
  description?: string,
  inline = true,
): { [k: string]: any } {
  return enumArg(inline ? 'inline-radio' : 'radio', name, options, description);
}

function selectArg(name: string, options: { [name: string]: string }, description?: string): { [k: string]: any } {
  return enumArg('select', name, options, description);
}

function multiSelectArg(name: string, options: { [name: string]: string }, description?: string): { [k: string]: any } {
  return enumArg('multi-select', name, options, description);
}

function checkArg(
  name: string,
  options: { [name: string]: string },
  description?: string,
  inline = true,
): { [k: string]: any } {
  return enumArg(inline ? 'inline-check' : 'check', name, options, description);
}

function enumArg(
  controlType: 'radio' | 'inline-radio' | 'check' | 'inline-check' | 'select' | 'multi-select',
  name: string,
  options: { [name: string]: string },
  description?: string,
): { [k: string]: any } {
  const arg = {};
  arg[name] = {
    options: Object.keys(options),
    name,
    type: 'text',
    description,
    control: {
      type: controlType,
      labels: options,
    },
  };
  return arg;
}

function boolArg(name: string, description?: string): { [k: string]: any } {
  const arg = {};
  arg[name] = {
    name,
    type: 'text',
    description,
    control: {
      type: 'boolean',
    },
  };
  return arg;
}

function setProps(selector: string, props: any, timeout = 0): void {
  setTimeout(() => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
      Object.keys(props).forEach((key) => {
        element[key] = props[key];
      });
    });
  }, timeout);
}

function insertBeforeAutoGenerated(readme: string, docToInsert: string): string {
  const index = readme.indexOf('<!-- Auto Generated Below -->');
  if (index < 0) {
    return readme + docToInsert;
  }
  return `${readme.substring(0, index)}${docToInsert}\n${readme.substring(index)}`;
}

export const StorybookHelper = {
  textArg,
  colorArg,
  dateArg,
  stringArg,
  numberArg,
  rangeArg,
  radioArg,
  selectArg,
  multiSelectArg,
  checkArg,
  enumArg,
  boolArg,
  setProps,
  insertBeforeAutoGenerated,
};
